import { defineStore } from 'pinia'
import type { Cat, SicknessObject } from '~/types/cat';
import type { Breed } from '~/types/breed'
import type { FormDTO } from '../types/DTO/formDTO';
import type { CatDTO, SicknessDTO } from '../types/DTO/catDTO';
import type { FunnelStatusEnum } from '../types/Enums/FunnelStatusEnum';
import type { SicknessQuestion } from '../types/sickness-question';
import type { Brand } from '../types/brand';
import type { BrandDTO } from '../types/DTO/brandDTO';

const useAcquisitionStore = defineStore({
  id: 'acquisition',
  persist: {
    storage: persistedState.localStorage,
  },
  state: () => ({
    'isLoading': false,
    'token': null as string | null,
    'email': null as string | null,
    'name': null as string | null,
    'optin': false,
    'status': null as FunnelStatusEnum | null,
    'cat_count': null as number | null,
    'current_cat_id': null as number | null,
    'cats': [] as Array<Cat>,
    'nextStep': null as string | null,
    'nextStepButtonDisabled': true,
    breeds: [] as Array<Breed>,
    sicknessQuestions: [] as Array<SicknessQuestion>,
  }),
  actions: {
    saveFormData(form: FormDTO) {
      this.token = form.token;
      this.email = form.email;
      this.name = form.name;
      this.optin = form.optin_email;
      this.status = form.status;
      this.cat_count = form.cat_number;
      this.current_cat_id = form.current_pet_id;
      this.cats = form.cats.map((cat: CatDTO) => ({
        id: cat.id,
        name: cat.name,
        gender: cat.gender_id === null ? null : cat.gender_id === 1 ? 'male' : 'female',
        breed: cat.breed === null ? null : {
          value: cat.breed.id,
          label: cat.breed.name,
        },
        age: {
          type: cat.age?.type || 'age',
          units: cat.age?.units || 'years',
          value: cat.age?.value || null,
          dateOfBirth: cat.age?.dateOfBirth ? cat.age.dateOfBirth : null,
        },
        neutered: {
          value: !!cat.neutered.value,
          planned: !!cat.neutered.planned,
          plannedDate: cat.neutered.plannedDate,
        },
        weight: {
          value: parseFloat(cat.weight?.value),
          estimation: !!cat.weight?.estimation,
        },
        fatness: cat.fatness,
        activity: cat.activity,
        form_step: cat.form_step,
        hasSicknessIssues: cat.has_health_issues,
        sicknesses: cat.sicknesses.reduce((acc: SicknessObject, sickness: SicknessDTO) => {
          acc[sickness.id] = sickness.value;
          return acc;
        }, {}),
        allergies: {
          value: cat.allergies.allergic,
          ingredients: {
            meat: cat.allergies.meat,
            cereals: cat.allergies.cereals,
          }
        },
        dry: {
          value: 'always',
          brand: {
            value: cat.dry.brand?.value || null,
            label: cat.dry.brand?.label || null,
          },
          preference: cat.dry.preference || null,
        },
        wet: {
          value: 'always',
          brand: {
            value: cat.wet.brand?.value || null,
            label: cat.wet.brand?.label || null,
          },
          preference: cat.wet.preference || null,
        },
      }));
      this.isLoading = false;
    },
    saveCatData(cat: CatDTO) {
      const index = this.cats.findIndex((c) => c.id === cat.id);
      this.cats[index] = {
        id: cat.id,
        name: cat.name,
        gender: cat.gender_id === null ? null : cat.gender_id === 1 ? 'male' : 'female',
        breed: {
          value: cat.breed?.id || null,
          label: cat.breed?.name || null,
        },
        age: {
          type: cat.age?.type || 'age',
          units: cat.age?.units || 'years',
          value: cat.age?.value || null,
          dateOfBirth: cat.age?.dateOfBirth ? cat.age.dateOfBirth : null,
        },
        neutered: {
          value: !!cat.neutered?.value || null,
          planned: !!cat.neutered?.planned || null,
          plannedDate: cat.neutered?.plannedDate || null,
        },
        weight: {
          value: parseFloat(cat.weight?.value),
          estimation: !!cat.weight?.estimation,
        },
        fatness: cat.fatness,
        activity: cat.activity,
        form_step: cat.form_step,
        hasSicknessIssues: cat.has_health_issues,
        sicknesses: cat.sicknesses?.reduce((acc: SicknessObject, sickness: SicknessDTO) => {
          acc[sickness.id] = sickness.value;
          return acc;
        }, {}),
        allergies: {
          value: cat.allergies.allergic,
          ingredients: {
            meat: cat.allergies.meat,
            cereals: cat.allergies.cereals,
          }
        },
        dry: {
          value: 'always',
          brand: {
            value: cat.dry.brand?.value || null,
            label: cat.dry.brand?.label || null,
          },
          preference: cat.dry.preference || null,
        },
        wet: {
          value: 'always',
          brand: {
            value: cat.wet.brand?.value || null,
            label: cat.wet.brand?.label || null,
          },
          preference: cat.wet.preference || null,
        },
      }
    },
    async createForm() {
      this.isLoading = true;
      const response = await $fetch<ApiResponse<FormDTO>>('funnel', {
        baseURL: useRuntimeConfig().public.apiBaseUrl,
        method: 'POST',
      });
      this.saveFormData(response.data);
    },
    async fetchBreeds() {
      if (this.breeds.length === 0) {
        const data = await $fetch('/breeds', {
          baseURL: useRuntimeConfig().public.apiBaseUrl,
        })
        this.breeds = data.map((breed) => {
          return {
            value: breed.value,
            label: breed.label,
            maleWeight: breed.male_weight,
            femaleWeight: breed.female_weight,
            suggested: breed.suggested
          }
        })
      }
    },
    async fetchForm() {
      if (!this.token) {
        return;
      }
      try {
        this.isLoading = true;
        const response = await $fetch<ApiResponse<FormDTO>>(`funnel/${this.token}`, {
          baseURL: useRuntimeConfig().public.apiBaseUrl,
          method: 'GET',
          headers: {
            'accept': 'application/json', // Trigger expectJson Laravel method. (added by default in PUT, POST, PATCH)
          }
        });
        this.saveFormData(response.data);
      } catch (error: any) {
        this.isLoading = false;
        // If 404, create a new form
        if (error.response.status === 404) {
          this.createForm();
        } else {
          this.isLoading = false;
          throw error;
        }
      }
    },
    async updateForm() {
      if (!this.token) {
        return;
      }
      this.isLoading = true;
      const response = await $fetch<ApiResponse<FormDTO>>(`funnel/${this.token}`, {
        baseURL: useRuntimeConfig().public.apiBaseUrl,
        method: 'PUT',
        body: {
          email: this.email,
          name: this.name,
          catCount: this.cat_count,
          currentCatId: this.current_cat_id,
          optinEmail: this.optin,
          optinSms: false, // TODO: Change to dynamic value when SMS optin is implemented
        }
      })
      this.saveFormData(response.data);
    },
    async updateCat(generateProfil: boolean = false, generateMenu: boolean = false) {
      if (!this.token || this.current_cat_id === null) {
        return;
      }
      this.isLoading = true;
      const response = await $fetch<ApiResponse<CatDTO>>(`funnel/${this.token}/cats/${this.current_cat_id}`, {
        baseURL: useRuntimeConfig().public.apiBaseUrl,
        method: 'PUT',
        body: {
          name: this.currentCat?.name,
          gender: this.currentCat?.gender,
          formStep: this.currentStep,
          breedId: this.currentCat?.breed?.value,
          age: {
            value: this.currentCat?.age.value,
            units: this.currentCat?.age.units,
            type: this.currentCat?.age.type,
            dateOfBirth: this.currentCat?.age.dateOfBirth,
          },
          neutered: {
            value: this.currentCat?.neutered.value,
            planned: this.currentCat?.neutered.planned,
            plannedDate: this.currentCat?.neutered.plannedDate,
          },
          weight: {
            value: this.currentCat?.weight.value,
            estimation: this.currentCat?.weight.estimation,
          },
          fatness: this.currentCat?.fatness,
          activity: this.currentCat?.activity,
          sicknesses: this.currentCat?.sicknesses,
          allergies: {
            allergic: this.currentCat?.allergies.value,
            meat: this.currentCat?.allergies.ingredients.meat,
            cereals: this.currentCat?.allergies.ingredients.cereals,
          },
          dry: {
            value: this.currentCat?.dry.value,
            brand: {
              value: this.currentCat?.dry.brand.value,
              label: this.currentCat?.dry.brand.label,
            },
            preference: this.currentCat?.dry.preference,
          },
          wet: {
            value: this.currentCat?.wet.value,
            brand: {
              value: this.currentCat?.wet.brand.value,
              label: this.currentCat?.wet.brand.label,
            },
            preference: this.currentCat?.wet.preference,
          },
          generate: {
            profil: generateProfil,
            menu: generateMenu,
          },
        }
      });
      this.saveCatData(response.data);
    },
    clear() {
      this.token = null;
      this.email = null;
      this.name = null;
      this.status = null;
      this.cat_count = null;
      this.current_cat_id = null;
      this.cats = [];
    },
    async reset() {
      this.clear();
      await this.createForm();
    },
    async setToken(token: string) {
      this.clear();
      this.token = token;
      await this.fetchForm();
    },
    async setCatCount(count: number) {
      this.cat_count = count;
      await this.updateForm();
    },
    async fetchSicknessQuestions() {
      console.log('fetching sickness questions');
      if (this.sicknessQuestions.length > 0) {
        return
      }
      this.sicknessQuestions = await $fetch('sickness-questions', {
        baseURL: useRuntimeConfig().public.apiBaseUrl,
        method: 'GET',
      });
    },
    async fetchKibbleBrands(search: string): Promise<Brand[]> {
      const data = await $fetch<BrandDTO[]>('/kibble', {
        params: {
          q: search
        },
        baseURL: useRuntimeConfig().public.apiBaseUrl,
      })
      // Transform data to Brand type
      return data.map((brand: BrandDTO): Brand => {
        return {
          id: brand.id,
          name: brand.name
        }
      })
    },
    async fetchPateBrands(search: string): Promise<Brand[]> {
      const data = await $fetch<BrandDTO[]>('/pate', {
        params: {
          q: search
        },
        baseURL: useRuntimeConfig().public.apiBaseUrl,
      })
      // Transform data to Brand type
      return data.map((brand: BrandDTO): Brand => {
        return {
          id: brand.id,
          name: brand.name
        }
      })
    },
    setNextStep(step: string) {
      this.nextStep = step;
    },
    setCurrentStep(step: string) {
      if (this.currentCat === null) {
        return;
      }
      this.currentCat.form_step = step;
    },
    async setNextCat() {
      if (this.currentCatIndex === -1 || this.currentCatIndex === this.cats.length - 1) {
        return;
      }
      this.current_cat_id = this.cats[this.currentCatIndex + 1].id;
      await this.updateForm();
    },
    resetCatIndex() {
      this.current_cat_id = this.cats[0].id;
    },
    setNextStepButtonDisabled(disabled: boolean) {
      this.nextStepButtonDisabled = disabled;
    },
    setAgeType(type: 'age' | 'dob') {
      this.currentCat!.age.type = type;
    },
    setWeight(value: number | null, estimation: boolean | null) {
      this.currentCat!.weight.value = parseFloat(value);
      this.currentCat!.weight.estimation = estimation || false;
    },
  },
  getters: {
    currentCat(): Cat | null {
      if (this.current_cat_id === null) {
        return null;
      }
      return this.cats.find((cat) => cat.id === this.current_cat_id) || null;
    },
    currentStep(): string {
      if (this.currentCat === null) {
        return 'many';
      }
      return this.currentCat.form_step;
    },
    currentCatIndex(): number {
      if (this.currentCat === null) {
        return -1;
      }
      return this.cats.findIndex((cat) => cat.id === this.current_cat_id);
    },
    getAgeType(): 'age' | 'dob' {
      if (this.currentCat === null) {
        return 'age';
      }
      return this.currentCat.age.type;
    },
  }
});

export default useAcquisitionStore;
